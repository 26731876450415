import React from "react";
import ChristmanVideo from "../../../static/videos/christmas_video.mp4";

const HeroBannerVideo = ({ heroBannerClass = "" }) => {
  return (
    <div className={`${heroBannerClass}`}>
      <video className="hero-banner__video" autoPlay loop muted playsInline width="100%" height="100%">
        <source src={ChristmanVideo} type="video/mp4" />
      </video>
      {/* <FindOutMoreBtn additionalClass="find-out-more-btn--video" /> */}
    </div>
  );
};

export default HeroBannerVideo;
